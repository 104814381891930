import { Container} from "react-bootstrap";
import how from "../assets/img/123123.png";

import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  return (
    <section className="project">
      <Container>
        <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeInBottomRight" : ""}>
                <img src={how} alt="how"/> 

              </div>}
        </TrackVisibility>

              <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeInBottomLeft" : ""}>
                <h2 className="pt-44" id="projects">Chart</h2>
              </div>}
        </TrackVisibility>

      <div className="ratio ratio-16x9">
                  <iframe src="https://dexscreener.com/base/0xe85A0c127422723D0862955C0AA4486d14E9EA73" title="dexscreener" allowfullscreen></iframe>
              </div>          <br /><br />

              

      </Container>
    </section>
    
  )
}


